import LongForm from "../Components/LongFormContent";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../Styles/Invitation.css';


function inviteCodeSubmit() {
    const codeValue = document.getElementById('inviteCode').value;
    if (codeValue === "" || codeValue.length !== 6 || codeValue.match(/^[0-9A-Za-z]+$/) === null) {
        alert.show('The code you entered is either invalid or has expired');
    }else{
        //it is alphanumeric
        window.location.href = "https://www.fusioncraft.org/invite/" + codeValue;
    }
}
    export default function RedeemInvite() {
        const {code} = useParams()
        const [data, setData] = useState({});
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);


        useEffect(() => {
            if (code === undefined) {
                // Normal Redeem code link thing

                setError(null);
                setData(null);
                setLoading(false);

                document.getElementById('CodeNotFound').style.display = 'block';
            } else {
                fetch('https://auth.fusioncraft.org/invite/' + code)
                    .then((response) => {
                        return response.json()
                    })
                    .then((actualData) => {
                        let serverResponse;
                        serverResponse = JSON.parse(actualData.Data)
                        if (actualData.Error === true) {
                            throw new Error(actualData.Message)
                        } else {

                            //
                            // Data we have is real!
                            setData({
                                StartOAuth: "https://auth.fusioncraft.org/start/" + code + "/discord"
                            })
                            let inviteCode = document.getElementById('inviteCode');
                            inviteCode.value = code;
                            inviteCode.readOnly = true;

                            document.getElementById('inviteSubmitBtn').style.display = 'none';
                            document.getElementById('CodeFound').style.display = 'block';
                            document.getElementById('CodeNotFound').style.display = 'none';
                        }

                    })
                    .catch((err) => {
                        setError(err.message);
                        setData(null);
                        document.getElementById('CodeNotFound').style.display = 'block';
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }, [code])

        return LongForm('redeem-invite-icon', "Invite Redemption",
            <div className="container">
                <div className="row InviteRedemption">
                    <div className="col-lg-12 m-1">
                        {loading && <div>A moment please...</div>}
                        {error && (
                            <div className="alert alert-danger">
                                <strong>Error! </strong> {error}
                            </div>
                        )}
                        <div id="CodeNotFound">
                            <div className="col-lg-10 m-auto">
                                <h2 className="p-0">Hello!</h2>
                                <p>Did someone send you a code to join FusionCraft? If so enter it in the form below to complete your account registration.</p>
                                <p>If you do not have an invite code, unfortunately you will need to get one before being able to continue.<br /> At this time FusionCraft is invite only. </p>
                                <br />
                            </div>
                        </div>
                        <div className="col-md-3 invite-form text-center">
                            <h5>Enter Invite Code Below:</h5>
                            <input type="text" className="form-control inviteField" id="inviteCode" placeholder="012A3FH" maxLength="6" />
                            <button  onClick={inviteCodeSubmit} className="btn btn-sm btn-primary" id="inviteSubmitBtn">Continue »</button>
                        </div>
                        <div id="CodeFound">
                            <div className="col-lg-10 m-auto">
                                <h2 className="p-0">Hello!</h2>
                                <p>We found your invite! Below are the social media options available to complete your account registration. Click one of the links shown below, follow the process to link your account, and start gaming!</p>
                                    <br />
                            </div>

                            {/* Discord Introduction for Whitelisting */}
                            <div className="col-md-5 m-auto text-center">
                                <h6 className="text-capitalize border-bottom">Social Media Login Options</h6>
                                <a href={data && data.StartOAuth} className="noUnderline">
                                    <div className="OAuthProvider DiscordOauth">
                                        <div className="oAuth-Action">Link Discord »</div>
                                    </div>
                                </a>
                                <br />
                            </div>

                            <div className="col-lg-10 m-auto RedemptionFooter text-center border-top">
                                <div className="text-gray-300 sm:text-left text-center"> <br /><strong>Note:</strong> Discord is currently the only Social Media Platform allowed for FusionCraft registration. </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }