import '../Styles/Footer.css'
function Footer() {
    return (
        <footer className="text-muted navbar fixed-bottom">
            <div className="container">
                <p>© 2022. All Rights Reserved. <a class="fc-link" href="/Privacy_Policy">Privacy Policy</a><br />

                    <a className="nav-link" rel="noreferrer" target="_blank" href="https://ko-fi.com/phelms">Support FusionCraft</a>
                </p>
                <p className="float-right">
                    <a class="fc-link" href="#">Back to top</a>
                </p>
            </div>
        </footer>
    );
}
export default Footer;
