import "../Styles/Home.css";
import { useAlert } from 'react-alert'

function Home() {

    const alert = useAlert()

    function inviteCodeSubmit() {
        const codeValue = document.getElementById('inviteCode').value;
        if (codeValue === "" || codeValue.length !== 6 || codeValue.match(/^[0-9A-Za-z]+$/) === null) {
            alert.show('The code you entered is either invalid or has expired');
        }else{
            //it is alphanumeric
            window.location.href = "https://www.fusioncraft.org/invite/" + codeValue;
        }
    }

    return (
        <main role="main">
            <section className="jumbotron text-center">
                <div className="container hero-image">
                    <div className="hero-text">
                        FusionCraft is a gaming community.<br />
                        Interested in joining?<br />
                        Join the <a href="https://discord.gg/6VWF7dzNgg" target="blank" className="whitelink">Discord</a> for more information!<br />
                    </div>
                    <div className="hero-button">
                        <a href="/About/" className="btn btn-sm btn-info">Learn More About FusionCraft »</a>
                    </div>
                </div>
            </section>
            <section className="jumbotron jumbotron-invite text-center">
                <div className="container invited-image">
                    <div className="title">Received An Invite?</div>
                    <div className="invite-form">
                        <h5>Enter Invite Code Below</h5>
                        <input type="text" className="form-control" id="inviteCode" placeholder="012A3FH" />
                        <button onClick={inviteCodeSubmit} className="btn btn-sm btn-primary">Complete Registration »</button>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row thumbnails-middle TestName">
                    <div className="col-md-3 p-0 m-0">
                        <div className="card p-0 m-0">
                            <a href="#Forums" target="_blank" rel="noreferrer">
                            <div className="card-body bg-forum p-0 m-0">
                                <div className="card-title">Community Forum</div>
                                <div className="coming-soon">Coming<br /> Soon</div>
                            </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-5 p-0 m-0">
                        <div className="card p-0 m-0">
                            <a href="Https://map.fusioncraft.org" target="_blank" rel="noreferrer">
                            <div className="card-body bg-map p-0 m-0">
                                <div className="card-title">Survival Map</div>
                            </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-3 p-0 m-0">
                        <div className="card p-0 m-0">
                            <a href="#Shop" target="_blank" rel="noreferrer">
                            <div className="card-body bg-shop p-0 m-0">
                                <div className="card-title">Merchandise</div>
                                <div className="coming-soon">Coming Soon</div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        </main>

    );
}
export default Home;
