import '../Styles/Header.css'
import {DetermineType, DeviceType} from './Util.js'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fontawesome from "@fortawesome/fontawesome-svg-core";
import {faBars} from "@fortawesome/free-solid-svg-icons";


function Header() {
    fontawesome.library.add(faBars);

    let LogoWidth;
    let VisibleNav = true;
    let ThisDevice = DetermineType();

    if (ThisDevice === DeviceType.MOBILE) {
        LogoWidth = "280";
    } else {
        LogoWidth = "360";
    }

    console.log(ThisDevice);
    function LoadNavBar() {
        if (ThisDevice === DeviceType.MOBILE || ThisDevice === DeviceType.TABLET) {
            document.getElementById("navBarIcon").style.display = "block";
            document.getElementById("navBarToggle").style.display = "none";
        } else {
            document.getElementById("navBarIcon").style.display = "none";
            document.getElementById("navBarToggle").style.display = "block";
        }
    }

    function ToggleNavBar() {
        if(VisibleNav) {
            document.getElementById("navBarToggle").style.display = "none";
            VisibleNav = false;
        } else {
            document.getElementById("navBarToggle").style.display = "block";
            VisibleNav = true;
        }
    }

    return (
        <header onLoad={LoadNavBar}>
            <div className="navbar box-shadow bg-fc">
                <div className="container d-flex justify-content-between">
                    <a href="https://www.fusioncraft.org" className="navbar-brand d-flex align-items-center">
                        <img src="/assets/Logo-Web.png" alt="FusionCraft" width={LogoWidth}/>
                    </a>
                    <div className="mobile-nav-button" id="navBarIcon" onClick={ToggleNavBar}>
                        <FontAwesomeIcon icon="bars"/>
                    </div>
                    <nav className="navbar navbar-expand-sm" id="navBarToggle">
                        <ul className="navbar-nav navbar-collapse">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/About">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/FAQ">FAQ</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" rel="noreferrer" target="_blank"
                                   href="https://status.fusioncraft.org">Server Status</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/mc">Minecraft Linking</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" rel="noreferrer" target="_blank"
                                   href="https://discord.gg/6VWF7dzNgg">Discord</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}
export default Header;
